@import 'src/assets/common.module';

.documents-list-top-scroll-wrapper {
  z-index: $z-index-column-header;
  position: sticky;
  top: 54px;
  overflow: auto;
  overflow-y: hidden;
  margin-top: -2px;

  &.hidden-scroll {
    height: 13px;
    margin-bottom: -11px;
  }

  .document-grid & {
    top: 30px;
  }

  & div {
    height: 1px;
  }
}
