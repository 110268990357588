@import 'src/assets/common.module';

.document-components-viewer {
  $top-left-bar-margin: 18px;
  $top-left-bar-height: 24px;
  $viewer-margin-top: 22px;
  margin: $viewer-margin-top 0 0 0;
  height: calc(100% - $top-left-bar-margin - $top-left-bar-height - $viewer-margin-top);
  position: relative;
}

.document-components-pending {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 0 auto;
  padding: 184px 0 0;
  color: $mid-gray;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  strong {
    font-size: 14px;
    margin: 16px 0 4px;
  }
}

.document-components-list {
  padding: 17px 32px 27px 34px;
  height: 100%;
  overflow-y: auto;
}

.document-component {
  border: 1px solid $light-gray;
  background: $white;
  padding: 24px;
  margin: 10px 0;

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: $dark-gray-text-color;
  }

  &__buttons {
    display: flex;
    gap: 8px;
    margin: 40px 0 0;

    .MuiButton-root {
      flex-grow: 1;
      border-radius: 8px;
      font-weight: bold;
      width: 100%;
      height: 32px;
      padding: 8px;

      &,
      &:hover {
        box-shadow: none;
      }
    }
  }
}

.document-component-context-menu.MuiButtonBase-root {
  float: right;
  margin: -12px;
  width: 30px;
  height: 30px;
  transition: opacity 150ms;
  opacity: 0;

  svg {
    width: 18px;
    height: 18px;
  }

  .document-component--menu-visible & {
    opacity: 1;
  }
}

.document-component-merge {
  text-align: center;
  margin: -27px 0;
  height: 44px;
  padding: 10px 0 0;
  transition: opacity 150ms;
  opacity: 0;

  &:last-child {
    display: none;
  }

  &:hover {
    opacity: 1;
  }

  .MuiButtonBase-root {
    background: $dark-red;
    color: $white;
    font-size: 11px;
    border-radius: 8px;
    height: 24px;
    padding: 4px;

    &:hover {
      background: darken($dark-red, 5%);
    }

    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }

    span {
      padding: 0 0 0 4px;
    }
  }
}

.document-components-loader {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiCircularProgress-root {
    color: $white;
  }
}
