.validate-modal.mars-modal {
  max-height: 98vh;

  .modal-description {
    overflow-y: auto;
  }

  div.clause-item .content {
    display: none;
  }
}
