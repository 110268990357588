@import 'src/assets/common.module';

.file-name-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-name-strong {
  font-weight: 700;
}
