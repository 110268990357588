@import 'src/assets/common.module';

.bulk-edit-users-group-form__attention {
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin: auto;

  & .users-list {
    padding: 0;
  }

  & p {
    color: $dark-gray-text-color;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  & .warning {
    color: $orange;
    width: 44px;
    height: 44px;
    margin: auto;
  }

  & ul {
    padding-inline-start: 24px;
    list-style: none;

    &.add {
      color: $green;

      & li:before {
        content: '+';
        margin-right: 4px;
      }
    }

    &.delete {
      color: $light-red;

      & li:before {
        content: '-';
        margin-right: 4px;
      }
    }
  }
}
