@import 'src/assets/common.module';

.document-update-status {
  $statusIconSize: 16px;

  &.done {
    color: $green;
    width: $statusIconSize;
  }

  &.error {
    color: $light-red;
    width: $statusIconSize;
  }

  &.warning {
    color: $orange;
    width: $statusIconSize;
  }
}
