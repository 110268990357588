@import 'src/assets/common.module';

button.nav-button {
  text-decoration: none;
  text-transform: none;
  height: 56px;
  color: $dark-gray-text-color;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-left: 34px;

  & svg {
    opacity: 0.6;
    margin-right: 16px;
  }

  &:hover,
  &.active {
    color: $dark-red;
    text-shadow: 0px 0px 1px black;
    background-color: transparent;
  }
}

.mars-menu {
  & a {
    text-decoration: none;
  }

  & .MuiMenu-paper {
    border-radius: 12px;
    min-width: 260px;
  }

  & ul[role='menu'] {
    padding: 0;
  }

  & li[role='menuitem'],
  & button {
    color: $dark-gray-text-color;
    letter-spacing: 0;
    height: 24px;
    font-size: 14px;
    padding: 20px;
    margin: 5px;

    & svg {
      opacity: 0.6;
      margin-right: 7px;
    }

    &:hover {
      background-color: $lighter-gray;
      border-radius: 8px;
    }

    & label {
      cursor: pointer;
    }
  }

  .mars-menu__link--disabled {
    pointer-events: none;
  }
}
