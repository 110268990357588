@import 'src/assets/common.module';

.search-filters {
  margin: 0 0 8px 1px;

  & .search-options {
    margin-bottom: 12px;
  }

  & .MuiOutlinedInput-root {
    margin-right: 17px;
    margin-bottom: 8px;
    border-radius: 12px;
    font-size: 0.75rem;

    &.filter-values-selected {
      background: $counter-lighter-red;
      color: $dark-red;
    }

    & .MuiSelect-select {
      padding-left: 8px;

      & strong {
        max-width: 80px;
        text-overflow: ellipsis;
        display: inline-block;
        overflow: hidden;
        vertical-align: bottom;
      }
    }
  }
}
