@import 'src/assets/common.module';

.user-profile {
  .nav-button {
    margin: 8px 0 8px 26px;

    .MuiSvgIcon-fontSizeMedium {
      margin: 0 0 0 4px;
    }
  }

  &-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark-gray-text-color;
    font-weight: bold;
  }

  &-logout-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light-red;
    font-weight: bold;
  }
}
