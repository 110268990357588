@import 'src/assets/common.module';

.mars-modal.upload-csv-metadata-modal {
  width: 440px;

  .upload-metadata-description {
    margin-top: 15px;
    height: 70px;
  }
}
