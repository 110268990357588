@import 'src/assets/common.module';

.compare-badge-column {
  .MuiDataGrid-root &.MuiDataGrid-cell {
    &--withRenderer:last-child,
    &--textLeft {
      justify-content: right;
    }
  }
}

.compare-badge {
  display: flex;
  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
  span {
    color: $dark-red;
    background: $counter-lighter-red;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    font-weight: bold;
    font-size: 0.7rem;
    width: 12px;
    height: 16px;
    line-height: 16px;
    margin: 10px 0 0 -10px;
  }
}
