.admin-panel__error-message {
  position: absolute;
  bottom: -18px;
  margin-left: 0px;
}

p.MuiFormHelperText-root {
  margin-top: 0;
  margin-left: 0;
}
