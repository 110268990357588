@import 'src/assets/common.module';

.dropdown-menu {
  margin-top: 10px;
  & .MuiMenu-list {
    overflow-y: auto;
    min-width: 200px;
    padding: 0;
  }

  .upload-file-item {
    &:hover,
    &.Mui-selected:hover {
      background-color: $dark-red;
      color: $white;
    }
  }

  .upload-draft-item {
    &:hover,
    &.Mui-selected:hover {
      background-color: $mid-gray;
      color: $white;
    }
  }
}
