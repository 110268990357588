@import 'src/assets/common.module';

.custom-pagination.MuiTablePagination-root {
  margin-bottom: 75px;
  width: 50%;
  overflow: visible;

  .MuiBox-root {
    flex-shrink: 0;
    order: -1;

    .MuiPagination-root {
      margin-left: -50%;

      .MuiPaginationItem-root {
        border-radius: 8px;
      }
    }
  }
}
