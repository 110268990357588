@import 'src/assets/common.module';

div.bookmarks-list {
  min-height: 80vh;
  border-radius: 12px;
  box-shadow: none;

  div.bookmarks-list-grid {
    .folder-name-column {
      display: flex;
      align-items: center;
      font-weight: bold;

      &__text {
        margin-left: 13px;
        margin-right: 9px;
        line-height: 0.8;
        color: $dark-gray-text-color;
      }
    }
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
