@import 'src/assets/common.module';
.document-compare {
  &.MuiGrid-item {
    display: flex;
    flex-direction: column;
  }

  .file-viewer {
    width: 100%;
    height: 100%;
  }

  &.MuiGrid-root {
    border: 2px solid $lightest-gray;
  }
}

.document-compare-header {
  height: 60px;
  position: relative;
  background: $lightest-gray;
  flex-shrink: 0;
  display: flex;
  padding: 0 60px 0 0;

  & .MuiToggleButtonGroup-root.buttons-tabs {
    margin: 12px 0 0 10px;
    height: 32px;
  }
}

.document-compare-menu {
  height: 24px;
  margin: 14px 0 0 7px;
  padding: 5px 5px 2px 3px;
  display: flex;
  align-items: center;

  & .document-download-button {
    color: inherit;
    line-height: inherit;
    text-transform: none;
    font-size: 12px;
    min-width: 30px;

    &:hover {
      border: 0;
    }
  }

  .document-compare-three & {
    .document-download-button .MuiButton-startIcon {
      @media (max-width: 1459px) {
        margin: 0;
      }
    }

    .document-download-button__label {
      display: none;

      @media (min-width: 1460px) {
        display: unset;
      }
    }
  }
}

.document-compare-doc-info-header {
  display: flex;
  justify-content: space-between;
  position: relative;

  & div.document-info-header {
    margin: 20px 10px 10px 15px;
    width: 100%;

    & .file-name-text {
      max-width: 95%;
    }
  }
}

span.toggle-content-switch {
  position: absolute;
  top: 10px;
  right: 15px;
}

.document-compare-window {
  height: 100%;
  width: 100%;
  max-height: calc(100% - 170px);

  &.metadata-list {
    overflow-y: scroll;
  }
}

.full-height {
  height: 100%;
}

.drag-icon {
  position: absolute;
  margin-left: 240px;
  margin-top: 18px;
  cursor: pointer;
  top: 0;
}

.droppable-clause-compare-panel {
  position: relative;
}
