@import 'src/assets/common.module';

.clause-item-related-docs {
  &-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  padding: 0 0 0 22px;
  width: 100%;
  max-height: 90%;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  .chip {
    font-size: 12px;
    height: 20px;
    margin: 2px;
    background-color: $gray-2;
  }
  .chip-label {
    color: $mid-gray;
  }

  .text {
    white-space: normal;
  }
}

.document-related-clauses {
  .row-current {
    &,
    &.MuiDataGrid-row:hover {
      background-color: $clause-highlight-opacity;
    }
  }
}
