@import 'src/assets/common.module';

.clause-compare-wrap {
  &-1,
  &-2,
  &-3,
  &-4,
  &-5,
  &-6,
  &-7,
  &-8,
  &-9,
  &-10 {
    display: grid !important;
  }

  &-1,
  &-2,
  &-3 {
    grid-template-rows: repeat(1, 100%);
    grid-auto-flow: column;
  }

  &-4,
  &-5,
  &-6,
  &-7,
  &-8,
  &-9,
  &-10 {
    grid-template-rows: repeat(2, 50%);
    grid-auto-flow: row;
  }

  &-2,
  &-4 {
    grid-template-columns: repeat(2, 50%);
  }

  &-3,
  &-5,
  &-6 {
    grid-template-columns: repeat(3, 33.33%);
  }

  &-7,
  &-8 {
    grid-template-columns: repeat(4, 25%);
  }

  &-9,
  &-10 {
    grid-template-columns: repeat(5, 20%);
  }
}
