@import 'src/assets/common.module';

div.document-grid {
  @include sticky-header;

  & .MuiDataGrid-root {
    border: none;

    .MuiTablePagination-root {
      margin-right: 25px;
    }
  }

  & .MuiDataGrid-columnHeader {
    height: 16px;
    letter-spacing: 0;
    line-height: 16px;
    text-transform: uppercase;
    padding-left: 0 !important;
  }

  & .MuiDataGrid-columnHeaderTitle {
    height: 16px;
    letter-spacing: 0;
    line-height: 16px;
    text-transform: uppercase;
    color: $mid-gray;
    font-size: 12px;
    font-weight: bold;
  }

  & .MuiDataGrid-columnHeaders {
    background-color: $lightest-gray;
  }

  & .MuiDataGrid-row {
    & > div:nth-child(1) {
      background-color: $lightest-gray;
      height: 16px;
      letter-spacing: 0;
      line-height: 16px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      color: $mid-gray;
      justify-content: center;
      padding: 0;
    }

    & > div:nth-child(2) {
      border-right: none;
    }
  }

  & .MuiDataGrid-cell--editing {
    color: inherit;
  }

  & .MuiDataGrid-editInputCell.Mui-error {
    background: adjust-color($light-red, $alpha: -0.8);
  }

  & .MuiDataGrid-columnSeparator {
    visibility: hidden;
  }

  & .uploading-status-cell {
    $statusIconSize: 16px;
    position: relative;
    top: 10%;

    & .uploading {
      transform: scaleY(-1);
      color: $dark-red;
      width: $statusIconSize;
    }

    & .done {
      color: $green;
      width: $statusIconSize;
    }

    & .error {
      color: $light-red;
      width: $statusIconSize;
    }

    & .warning {
      color: $orange;
      width: $statusIconSize;
    }
  }

  .document-grid-row-error {
    background: $error-row-bg;
    border: 1px solid $error-row-border;

    .MuiDataGrid-cell:first-child {
      margin: -1px 0 0 -1px;
      border-right: 1px solid $error-row-border;
    }
  }

  .document-grid-cell-error {
    background: rgba($light-red, 0.2);
  }

  & .MuiCheckbox-root .MuiSvgIcon-fontSizeMedium {
    color: $light-gray;
    width: 18px;
    height: 18px;
  }

  & .Mui-checked {
    &.MuiCheckbox-root .MuiSvgIcon-fontSizeMedium {
      color: $dark-red;
      width: 18px;
      height: 18px;
      outline: none;
    }
  }

  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-cell:focus-within {
    outline: none;
  }

  & .date-input {
    font-size: inherit;
    padding: 0 9px;

    &.Mui-error {
      background: adjust-color($light-red, $alpha: -0.8);
    }

    & svg {
      width: 0.7em;
    }
  }
}

.grid-footer {
  & .MuiDataGrid-footerContainer {
    padding-top: 80px;
  }
}
