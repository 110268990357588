@import 'src/assets/common.module';

.documents-list-grid.pending-documents-list-grid {
  padding: 9px 20px 40px 30px;

  & > .MuiDataGrid-main {
    & > .MuiDataGrid-columnHeaders {
      border-bottom: none;
    }
  }

  & .MuiDataGrid-footerContainer {
    border-top: none;
  }

  .failure-reason-column {
    white-space: normal;
  }

  .clickable {
    cursor: pointer;
  }
}
