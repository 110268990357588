@import 'src/assets/common.module';

.reports-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  & .reports-section-paper {
    border-radius: 12px;
    padding: 20px;
  }
  & .reports-section-paper:first-child {
    border-radius: 0 12px 12px 12px;
  }
}
