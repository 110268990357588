@import 'src/assets/common.module';

div.generate-confirmation__modal {
  width: 440px;
}

div.generate-confirmation__modal-buttons {
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  & .confirm-button {
    width: 100%;
  }
}
