@import 'src/assets/common.module';

.comment {
  margin-bottom: 30px;
  font-size: 14px;

  &--header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;

    div:nth-child(1) {
      display: flex;
      align-items: center;
      font-weight: bold;
      > svg {
        opacity: 0.6;
        margin-right: 1rem;
      }
    }
    div:nth-child(2) {
      color: $mid-gray;
      margin-left: 20px;
    }
  }

  &--content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &--expanded {
      -webkit-line-clamp: unset;
    }
  }

  &--read-more-button {
    font-family: $font-family;
    font-size: 14px;
    background-color: transparent;
    border: none;
    color: $dark-red;
    font-weight: bold;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.comment .comment--header .comment--header--delete-button {
  margin-left: auto !important;
  background: none;
  width: 24px;
  padding: 0;

  svg {
    cursor: pointer;
    color: $mid-red;
  }

  &.MuiLoadingButton-root {
    & .MuiTouchRipple-root {
      display: none !important;
    }
  }

  &.disabled {
    svg {
      opacity: 0.6;
      cursor: none;
    }
  }
}
