@import 'src/assets/common.module';

.clause-status-icon {
  margin-top: -5px;

  &--green {
    color: $green;
  }

  &--red {
    color: $mid-red;
  }
}
