@use 'sass:list';
@import 'src/assets/common.module';

.admin-panel-title {
  height: 24px;
  width: 198px;
  color: $dark-gray-text-color;
  font-family: $font-family;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 0 0 19px;
}

.slider {
  position: relative;
  display: flex;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.5px;
    background-color: $dark-red;
  }

  &--line {
    position: relative;
    height: 1.5px;
    background-color: $dark-red;
    margin-top: 24px;
    margin-bottom: 0;

    $total-cards: 0, 1, 2, 3, 4;
    $selectable-cards: 0, 1, 2;
    $panel-card-with: 240px;
    $panel-card-margin: 20px;
    $panel-card-with-margins: calc($panel-card-with + $panel-card-margin);
    $total-cards-length: list.length($total-cards);
    width: calc($panel-card-with-margins * $total-cards-length - $panel-card-margin);

    svg {
      position: absolute;
      top: -11px;
      transition: left 0.2s ease;
      transform: translateX(-50%);

      @each $card in $selectable-cards {
        &.selected-#{$card} {
          left: calc(($panel-card-with-margins * $card) + $panel-card-with * 0.5);
        }
      }
    }
  }
}
