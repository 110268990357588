@import 'src/assets/common.module';

.logo {
  height: 24px;
  width: 81.6px;
}

header {
  &.app-bar {
    background-color: $white;
    height: 72px;
    min-width: 1350px;
  }
  & .upload-files {
    margin-right: 13px;
  }
  & a {
    &.nav-link {
      text-decoration: none;
      height: 56px;
      color: $dark-gray-text-color;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 34px;

      & svg {
        opacity: 0.6;
        margin-right: 16px;
      }

      &:hover,
      &.active {
        color: $dark-red;
        text-shadow: 0 0 1px black;
      }
    }
    &.disabled-link {
      color: $mid-gray;
      pointer-events: none;
    }
  }
  & .top-notification {
    margin-left: 10px;

    & span {
      background-color: $dark-red;
    }
  }

  & .spacer {
    min-width: 34px;
    flex-grow: 1;
  }
}
