@import 'src/assets/common.module';
.comment-wording {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: auto;
  --error-form-helper-height: 17px;

  & p.MuiFormHelperText-root {
    height: var(--error-form-helper-height);
  }

  & .MuiTextField-root {
    flex-grow: 1;

    & textarea {
      height: 100% !important;
    }

    & .MuiFilledInput-root {
      padding: 7px;
      height: 120px;
    }
  }

  & .custom-text-field-counter {
    margin-bottom: 0;
    margin-top: calc(var(--error-form-helper-height) * -1);
    height: var(--error-form-helper-height);
  }

  & .MuiFilledInput-root {
    flex-grow: 1;
  }
}
