@import 'src/assets/common.module';

.chat-modal {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 100%;
  background: $white;

  & .document-compare {
    height: 100%;
  }
}

.chat-modal-root.MuiModal-root {
  z-index: $z-index-mui-snackbar;
  height: 100%;
}

.chat-pane {
  height: 100%;
  background-color: $white;
}

.chat-agreement-modal {
  .confirmation-modal-message span {
    display: block;
    margin-bottom: 7px;

    svg {
      vertical-align: bottom;
      margin-right: 5px;
    }
  }

  .warning-icon {
    text-align: center;
    color: $orange;
    width: 18px;
    height: 18px;
  }

  & .confirmation-modal-header .confirmation-modal-message {
    padding: 0;
  }
}
