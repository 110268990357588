@import 'src/assets/common.module';

.bookmarks {
  div.bookmarks-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0 16px 0;
    background-color: $gray;
    height: 72px;

    & .bookmarks-title {
      height: 24px;
      width: 198px;
      color: $dark-gray-text-color;
      font-family: $font-family;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0 0 0 19px;
    }
  }
}
