@import 'src/assets/common.module';

.tag-input {
  border: 1.5px solid rgba(0, 0, 0, 0.23);
  padding: 3px 4px;
  border-radius: 12px;
  min-height: 50px;

  &--small {
    min-height: 32px;
  }

  & .MuiChip-root {
    border-radius: 8px;
    margin-right: 6px;

    & .MuiChip-deleteIcon {
      display: none;
    }

    &:hover {
      background: $counter-lighter-red;
      color: $dark-red;

      & .MuiChip-deleteIcon {
        display: initial;
      }
    }
  }

  & input {
    width: 230px;
    height: 24px;
    border: none;
    font-family: inherit;
    font-size: 12px;
    outline: none;
  }
}
