@import 'src/assets/common.module';

.edit-save-search-modal {
  width: 650px;
  height: 90%;
  max-height: 700px;

  & .api-error {
    margin-bottom: 5px;
  }

  &.mars-modal button.dismiss {
    width: 100%;
  }

  &.mars-modal .modal-description {
    width: 100%;
    max-height: calc(100% - 80px);
  }
}

.edit-search-modal-content {
  max-height: 100%;
  margin-bottom: 40px;
  overflow: auto;
  .edit-search-modal-content__show-to {
    margin: 15px 0;
    position: relative;

    .edit-search-modal-content__show-to-switch {
      position: absolute;
      right: 0;
      top: -9px;
    }
  }

  .sequential-search-fields-arrow-line {
    margin-right: 1px;
  }

  .edit-search-modal-content__search-name {
    margin: 10px 0;

    .edit-search-modal-content__title {
      margin-bottom: 10px;
    }
  }

  .edit-search-modal-content__filters {
    margin: 10px 0 10px 0;
    .edit-search-modal-content__filters-content {
      margin-top: 10px;
    }
  }

  .sequential-search-options-wrapper {
    padding: 0 0 15px 0;
  }
}

.edit-search-modal-content__title {
  font-weight: bold;
  text-transform: uppercase;
  color: $mid-gray;
}
