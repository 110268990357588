@import 'src/assets/common.module';

.metadata-edit-modal {
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
  height: 85%;
  background: $white;
  border-radius: 12px;
  outline: none;
  min-width: 1255px;
  overflow: hidden;

  &-header {
    height: 60px;
  }

  &-metadata {
    height: calc(100% - 60px);
    overflow: auto;
  }

  .back-button {
    position: absolute;
    top: 20px;
    left: 24px;
    font-weight: bold;
    color: $dark-gray-text-color;
    cursor: pointer;

    & svg {
      vertical-align: middle;
    }
  }

  .modal-close {
    right: 32px;
    top: 20px;
  }

  .MuiGrid-container {
    height: 100%;
  }

  .document-view {
    background: $lightest-gray;
    height: 100%;
    max-width: calc(100% - 548px);

    .file-viewer {
      margin: 60px auto 0 auto;
      width: 93%;
      height: calc(100% - 67px);
    }
  }

  .modal-title {
    margin: 10px 10px 0 30px;
    position: relative;

    & img {
      position: absolute;
      width: 28px;
      height: 32px;
    }

    & .file-name-text {
      display: block;
      font-size: 1.1rem;
      letter-spacing: 0.013em;
      margin-left: 40px;
      padding: 5px;
    }
  }

  .form-view {
    width: 548px;
    height: 100%;
  }

  .uploading-status,
  .drag-and-drop {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 60px;
    height: 100%;
  }

  .uploading-status-text {
    margin-left: 8px;
  }

  .error {
    color: $light-red;
  }
}
