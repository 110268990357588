@import 'src/assets/common.module';

.search-options {
  & .MuiTypography-root:first-child {
    color: $mid-gray;
    font-weight: bold;
  }

  & .MuiFormGroup-root {
    padding: 7px 3px;
  }

  & .MuiRadio-root,
  & .MuiCheckbox-root {
    padding: 10px;
  }

  & .MuiSvgIcon-root {
    font-size: 16px;
  }

  & .MuiTypography-root {
    font-size: 0.75rem;
  }

  & .documents-search-excluded {
    padding: 4px 0 0 0;

    & .documents-search-counter {
      margin-right: 4px;
    }
  }
}
