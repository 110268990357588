@import 'src/assets/common.module';

.pending-status {
  display: flex;
  align-items: center;

  & svg {
    width: 19px;
    height: 19px;
  }

  &__label {
    margin: 0 0 0 6px;
    line-height: 19px;
  }

  &.uploading svg {
    color: $dark-red;
  }

  &.error svg {
    color: $light-red;
  }

  &.warning svg {
    color: $orange;
  }
}
