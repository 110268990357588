@import 'src/assets/common.module';

.handle-selector {
  white-space: pre-line;
  user-select: none;

  &__char {
    position: relative;
    padding: 2px 0 3px 0;

    &--selected {
      background: $lighter-orange;
    }
  }

  &__handle {
    cursor: ew-resize;
    display: inline-block;
    vertical-align: top;
    width: 1px;
    height: 24px;
    position: relative;
    margin: 0 -1px 0 0;
    z-index: 1;

    &--is-dragging {
      pointer-events: none;
    }

    &:before {
      content: '';
      display: block;
      background: $primary-dark;
      width: 20px;
      height: 20px;
      position: absolute;
      border-radius: 100%;
    }

    &--start:before {
      border-top-right-radius: 0;
      top: 0;
      left: -20px;
    }

    &--end:before {
      border-top-left-radius: 0;
      top: 100%;
      left: 0;
    }
  }
}
