@import 'src/assets/common.module';

.uploadfiles-popup-wrapper.MuiModal-root {
  z-index: $z-index-mui-snackbar;
}

.uploadfiles-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  border-radius: 12px;
  outline: none;

  &__content {
    padding: 32px 35px;
    display: flex;
    text-align: center;
  }

  &__item {
    width: 208px;
    height: 173px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  & button.MuiButton-root {
    background-color: $counter-lighter-red;
    width: 105px;
    height: 45px;
    border-radius: 12px;
    margin: 0 auto;
    text-transform: none;
    &:hover {
      background-color: $lighter-red;
    }
  }

  &__separator {
    border: 1px solid $lighter-gray;
    margin: 0 34px;
  }
  &__title {
    color: $dark-gray-text-color;
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
    letter-spacing: 0;
  }
  &__text {
    color: $mid-gray;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    height: 32px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 24px;
  }

  & svg {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    color: $mid-gray;
  }
  &__button {
    text-decoration: none;
  }
}
