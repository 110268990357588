@import 'src/assets/common.module';

.save-search-button.MuiButton-root {
  margin: -2px 0 0 13px;
  text-transform: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;

  & .MuiButton-startIcon {
    margin-right: 3px;

    svg {
      font-size: 16px;
    }
  }
}
