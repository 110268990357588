@import 'src/assets/common.module';

div.upload-file-data-grid {
  padding: 30px;
  color: $mid-gray;
  border: none;

  & .MuiCheckbox-root .MuiSvgIcon-fontSizeMedium {
    color: $light-gray;
    width: 18px;
    height: 18px;
  }

  & .Mui-checked {
    &.MuiCheckbox-root .MuiSvgIcon-fontSizeMedium {
      color: $dark-red;
      width: 18px;
      height: 18px;
    }
  }

  & .MuiDataGrid-columnSeparator {
    visibility: hidden;
  }

  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  }

  & .MuiDataGrid-columnHeader:focus-within,
  & .MuiDataGrid-cell:focus-within {
    outline: none;
  }

  & img {
    margin-left: auto;
  }

  & svg {
    margin-left: auto;
  }

  & .uploading-status {
    display: flex;
    justify-content: center;
    align-items: center;

    & .uploading {
      transform: scaleY(-1);
      color: $dark-red;
    }

    & .done {
      color: $green;
    }
  }

  & .error {
    color: $light-red;
  }

  & .warning {
    color: $orange;
  }

  & .uploading-status-text {
    margin-left: 8px;
  }

  & .MuiDataGrid-row .Mui-selected {
    outline: none;
  }

  & .file-name-text {
    max-width: 100%;
  }
}
