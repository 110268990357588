@import 'src/assets/common.module';

button.drop-down {
  width: 40px;
  border-left-style: solid;
  border-left-width: thin;
  border-color: $gray;
  & .MuiButton-startIcon {
    margin-right: 0;
  }
}
