@import "src/assets/common.module";

button.full-screen-grid {
  color: $mid-gray;
  height: 16px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: none;

  & svg{
    height: 14px;
    width: 14px;
  }
}
