@import "src/assets/common.module";

div.search-grid {
  color: $mid-gray;
  height: 16px;
  width: 400px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: none;
  padding: 13px 0;

  & svg{
    height: 18px;
    width: 18px;
  }
}
