@import 'src/assets/common.module';

.filter-panel-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .filter-panel-item-field {
    font-size: 12px;
    line-height: 24px;
    color: $mid-gray;
  }

  .filter-panel-item-field-value {
    font-size: 12px;
    line-height: 24px;
    color: $dark-gray-text-color;
    font-weight: bold;
  }

  .filter-panel-item-field-icon {
    width: 1rem;
    height: 1rem;
  }
}
