@import 'src/assets/common.module';

.documents-tabs {
  & .tab-panel {
    padding: 0;
    border-radius: 0 12px 12px 12px;
    background: $white;
  }

  & .MuiTab-root {
    border-radius: 12px 12px 0 0;
    background-color: rgba(255, 255, 255, 0.4);
    color: $dark-gray-text-color;
    text-transform: none;
    margin-right: 3px;

    &:nth-child(1) {
      width: 183px;
    }

    &:nth-child(2) {
      width: 158px;
    }

    &.Mui-selected {
      border-radius: 12px 12px 0 0;
      background-color: $white;
      font-weight: bold;
      color: $dark-gray-text-color !important;
    }
  }

  & .MuiTabs-indicator {
    display: none;
  }
}
