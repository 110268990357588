@import 'src/assets/common.module';

.info-page {
  font-size: 12px;
  line-height: 16px;
  height: 92vh;
  color: $dark-gray-text-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-icon-circle {
    background-color: $light-gray-2;
    border-radius: 100px;
    width: 148px;
    height: 148px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }

  &-header {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 40px;
  }

  &-text {
    margin: 23px 0 16px 0;
    font-size: 16px;
    line-height: 24px;
    max-width: 590px;
    text-align: center;

    a {
      color: $dark-red;
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
