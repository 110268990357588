@import 'src/assets/common.module';

.MuiToggleButtonGroup-root.buttons-tabs {
  padding: 2px 3px;
  background: $gray;
  border-radius: 12px;

  .MuiButtonBase-root {
    text-transform: none;
    height: 28px;
    width: 103px;
    border: none;
    border-radius: 7px;
    color: $dark-gray-text-color;
    font-size: 14px;
    line-height: 23px;

    &.Mui-selected {
      font-weight: bold;
      background: $dark-red;
      color: $white;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 1px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
