@import 'src/assets/common.module';

div.bookmarks-search {
  background-color: $white;
  margin-bottom: 10px;
  border-radius: 16px;
  display: flex;

  & .search-field.MuiTextField-root {
    margin-right: 0;
    flex-grow: 1;
  }
}
