@import 'src/assets/common.module';

div.drag-and-drop {
  padding: 30px 0;

  & .drag-and-drop-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 12px;
    border-color: $light-gray;
    border-style: dashed;
    background-color: $white;
    color: $light-gray;
    outline: none;
    transition: border 0.24s ease-in-out;

    &.focus {
      border-color: $light-gray;
    }

    &.accept-style {
      border-color: $green;
    }

    &.reject-style {
      border-color: $dark-red;
      cursor: not-allowed;
      background-color: $top-light-red;
    }
  }

  & div.drag-and-drop-space {
    height: 128px;
    border: 1.5px dashed $light-gray;
    border-radius: 12px;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  & .dnd-msg-title {
    height: 24px;
    width: 280px;
    color: $mid-gray;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;

    & button {
      font: inherit;
      color: $dark-red;
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      display: inline;
      margin: 0;
      padding: 0;
    }
  }

  & .dnd-msg-subtitle {
    height: 32px;
    width: 169.61px;
    color: $mid-gray;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }

  & .sensitive-data-warning {
    width: 400px;
    font-weight: bold;
  }
}
