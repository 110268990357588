@import 'src/assets/common.module';

.terms-and-conditions {
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 25px;
  background-color: $lightest-gray;
  overflow-y: scroll;
  height: calc(100% - 70px);

  & .MuiTypography-root.MuiTypography-body1 {
    font-size: 12px;
    line-height: 16px;
  }
}

.mars-modal.terms-and-conditions-modal {
  width: 60%;
  min-width: 430px;
  height: 80%;
  align-items: center;

  & .MuiTypography-root.MuiTypography-subtitle1 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    line-height: 20px;
  }
}
