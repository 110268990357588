@import 'src/assets/common.module';

button.document-bar-list__file {
  height: 38px;
  width: 85%;
  max-width: 15%;
  border-radius: 9px;
  background-color: $white;
  font-size: 14px;
  text-transform: none;
  display: flex;
  justify-content: flex-start;

  &.file-to-compare {
    background-color: $top-light-red;
  }

  &:hover {
    background-color: $gray;

    & .MuiButton-endIcon {
      visibility: visible;
      margin-left: auto;
      border-radius: 4px;
      border: 1px solid $light-gray;
    }
  }

  & span:nth-child(2) {
    margin-top: 3px;
    max-width: 68%;
  }

  & span {
    color: $dark-gray-text-color;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 1px;
  }

  & .MuiButton-startIcon {
    line-height: normal;
    margin-left: 0;
    margin-right: 6px;
    width: 20px;
    flex-shrink: 0;
  }

  & .MuiButton-endIcon {
    visibility: hidden;
    border: 1px solid $light-gray;
    margin-left: auto;
    flex-shrink: 0;
  }
}
