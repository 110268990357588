@import 'src/assets/common.module';

.reported-field {
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: $light-orange;
  padding: 8px 12px;
  background-color: rgba(232, 122, 22, 0.2);
  margin-bottom: 12px;
}
.reported-field-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reported-field-title {
  display: flex;
}

.reported-field-title span {
  margin-left: 12px;
}
.reported-field-edit {
  font-size: 12px;
  color: $mid-gray;
  margin-top: 8px;
}

.reported-field-edit div {
  font-weight: 400;
  color: $mid-gray;
}

.reported-fields-value {
  color: $dark-gray-text-color;
  font-weight: bold;
}

.reported-field-edit label {
  color: $dark-gray-text-color;
}

.popper-content {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.reported-fields-label {
  font-size: 12px;
  line-height: 24px;
  color: $mid-gray;
  font-weight: 400;
}

.reported-fields-value {
  font-size: 12px;
  line-height: 24px;
  color: $dark-gray-text-color;
  font-weight: bold;
}
