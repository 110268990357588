.document-files-compare-modal {
  width: 100%;
  height: 100%;
  top: 0;

  .MuiGrid-item {
    height: 100%;
  }

  .file-viewer {
    &--pdf,
    &--html {
      height: 100%;
    }
  }
}
