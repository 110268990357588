@import "src/assets/common.module";

.metadata-csv-menu {
  & .MuiMenu-paper {
    border-radius: 12px;
    width: 260px;
  }

  & ul[role='menu'] {
    padding: 0;
  }

  & li[role='menuitem'], & button {
    color: $dark-gray-text-color;
    letter-spacing: 0;
    height: 24px;
    font-size: 14px;
    padding: 20px;
    margin: 5px;

    & svg {
      opacity: 0.6;
      margin-right: 7px;
    }

    &:hover {
      background-color: $lighter-gray;
      border-radius: 8px;
    }

    & label {
      cursor: pointer;
    }

  }
}
