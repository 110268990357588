@import 'src/assets/common.module';

div.bookmarks-modal {
  height: 300px;
  div.bookmarks-modal-select {
    display: block;
  }
  .create-button {
    text-transform: none;
    font-weight: bold;
    font-size: 12px;
    line-height: 1;
  }
}
.bookmarks-modal-title {
  height: 24px;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  width: 100%;
}

.bookmarks-modal-content {
  margin-top: 12px;
  height: 60px;
}

.bookmarks-modal-title-block {
  display: flex;
  align-items: center;
  svg {
    font-size: 15px;
  }
}

.bookmarks-input {
  width: 100%;
  margin-top: 20px;
}

.modal-previous {
  cursor: pointer;
  margin-right: 12px;
}
