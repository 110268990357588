@import 'src/assets/common.module';

.compare-options-bar {
  display: flex;
  background-color: $lightest-gray;
  padding-left: 20px;
  align-items: center;
  height: 54px;

  > :last-child {
    margin-left: 20px;
  }
}
