@import 'src/assets/common.module';

@mixin button-styles {
  border-radius: 12px;
  box-shadow: none;
  text-transform: none;
  font-size: 14px;
  font-weight: bold;
  padding-top: 11px;
  height: 50px;
  width: 230px;
  border: 1px solid $light-gray;

  &:hover {
    box-shadow: none;
    border: 1.5px solid $light-gray;
  }
  &:disabled {
    background-color: $gray-mui-disabled-button-background;
  }
}

.admin-panel__create-user {
  margin-top: 35px;
  padding: 40px 30px;
  display: flex;

  &__filters {
    position: relative;
    display: grid;
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    grid-gap: 30px;
    width: 100%;
    margin-bottom: 114px;

    & .MuiOutlinedInput-root {
      height: 50px;
      border-radius: 12px;

      &.filter-values-selected {
        background: $counter-lighter-red;
        color: $dark-red;
      }

      & .MuiSelect-select {
        padding-left: 8px;

        & strong {
          text-overflow: ellipsis;
          overflow: hidden;
          vertical-align: bottom;
        }
      }
    }

    .MuiTextField-root {
      & .MuiInputBase-input {
        padding-left: 8px;
      }
    }
  }

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    &__error {
      position: absolute;
      bottom: 114px;
    }
  }

  &__buttons {
    .MuiButton-root {
      @include button-styles;
    }
    &__cancel-button {
      color: $mid-gray;
      margin-left: 0px;
    }

    .MuiLoadingButton-root.MuiButton-root {
      margin-left: 30px;
      background-color: #8e0038;

      &:hover {
        background-color: $mid-red-2;
      }

      &:disabled {
        background-color: $gray-mui-disabled-button-background;
      }
    }
  }
}
