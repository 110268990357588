@import "src/assets/common.module";

.upload-progress {

  & .MuiSnackbarContent-root {
    border-radius: 13px;
    line-height: 1.46;
    padding: 3px 10px;
  }

  & .MuiSnackbarContent-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    width: 556px;
  }

  & strong {
    margin-right: 8px;
  }

  & small {
    height: 16px;
    width: 53px;
    opacity: 0.6;
    color: $light-gray;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }

  $icon-size: 20px;

  & .warning {
    color: $orange;
    width: $icon-size;
    margin-right: 8px;
  }

  & .uploading {
    color: $mid-red;
    margin-right: 8px;
  }

  & .done {
    color: $green;
    width: $icon-size;
    margin-right: 8px;
  }

  & .error {
    color: $light-red;
    width: $icon-size;
    margin-right: 8px;
  }

  & .submit-button {
    border-radius: 12px;
    height: 32px;
    width: 101px;
    color: $white;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    margin-left: auto;

    &:disabled {
      color: $white;
      background-color: $mid-gray;
    }

    &.MuiLoadingButton-loading {
      color: transparent;
    }
  }
}
