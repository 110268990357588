@import 'src/assets/common.module';

div.clause-item {
  margin: auto 0;
  width: 100%;
  &.clause-item-clickable {
    &,
    .MuiChip-root {
      cursor: pointer;
      line-height: 20px;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    .header-left {
      display: grid;
      align-items: center;
      grid-template-columns: auto auto auto 1fr;
    }
    .header-right {
      display: flex;
      gap: 10px;
    }
    .MuiChip-root {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      height: 20px;
    }
    .title {
      max-width: 920px;
      margin-right: 16px;
      color: $dark-gray-text-color;
      overflow: hidden;
    }
  }
  .content {
    color: $mid-gray;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    margin-bottom: 28px;
  }

  .metadata {
    display: flex;
    flex-wrap: wrap;
    font-weight: 700;
    text-transform: uppercase;
  }

  .provider {
    color: $mid-gray;
    margin-right: 16px;
  }

  .tag {
    margin-right: 16px;
    background-color: $light-gray;
    color: $light-red;
  }
}
