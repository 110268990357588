@import 'src/assets/common.module';

.no-documents-found {
  padding: 16px;
  text-align: center;

  & .no-documents-found-box {
    margin-top: 239px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $light-gray;
  }

  & .no-documents-found-msg-title {
    margin-top: 20px;
    height: 24px;
    color: $mid-gray;
    font-weight: bold;
    line-height: 24px;
  }

  & .no-documents-found-msg-subtitle {
    height: 16px;
    color: $mid-gray;
    font-size: 12px;
    line-height: 16px;
  }

  & .no-documents-found-icon {
    height: 52px;
    width: 52px;
  }

  button {
    margin-top: 20px;
    height: 32px;
  }
}
