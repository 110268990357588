@import 'src/assets/common.module';

button.compare-button {
  border-radius: 9px;
  text-transform: none;
  font-weight: bold;
  min-width: 158px;
  height: 38px;
  justify-content: center;
  background-color: $dark-red;
  color: $counter-lighter-red;

  &:disabled {
    background-color: $mid-gray;
    color: $white;
  }

  &:hover {
    background-color: $dark-red;
  }
}

.compare-button {
  &__counter {
    border-radius: 4px;
    opacity: 1;
    height: 24px;
    width: 38px;
    display: flex;
    justify-content: center;

    &.active {
      color: $dark-red;
      background-color: $counter-lighter-red;
    }

    &.disabled {
      background-color: $lighter-gray;
      color: $mid-gray;
    }

    & div {
      margin: auto 0;
      font-size: 12px;
      font-weight: 700;
      line-height: 10px;
    }
  }

  &__text {
    margin-top: 3px;
  }
}
