@import 'src/assets/common.module';

.my-files {
  .documents-search {
    margin: 0 0 28px 0;
  }
}

div.my-files-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 0 16px 0;
  background-color: $gray;
  height: 72px;

  & .my-files-title {
    height: 24px;
    width: 198px;
    color: $dark-gray-text-color;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0 0 0 19px;
  }

  &.fullscreen {
    padding: 10px 10px 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    justify-content: end;
    height: 54px;
    & .my-files-title {
      display: none;
    }
  }
}
