@import 'src/assets/common.module';
@import 'src/components/Documents/DocumentViewModal/DocumentViewModal';

.metadata-edit-modal.metadata-edit-modal-ach {
  .document-view {
    @include ach-modal-file-viewer;

    .document-view__top-bar {
      display: flex;
      align-items: center;
      height: 24px;
      margin: 18px 0 0 0;
      padding: 0 0 0 32px;
    }
  }

  .metadata-form {
    margin-top: 21px;
  }
}
