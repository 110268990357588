@import 'src/assets/common.module';

.edit-users-groups-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 840px;
  min-height: 168px;
  background: $white;
  border-radius: 12px;
  outline: none;
  overflow: hidden;

  &.attention-modal {
    width: 400px;
  }

  .edit-users-group-form__edit-user {
    padding: 32px 24px;
    display: flex;
    flex-direction: column;

    &__filters {
      position: relative;
      display: grid;
      grid-template-columns: calc(50% - 15px) calc(50% - 15px);
      grid-gap: 30px;
      width: 100%;

      .form-warning {
        font-size: 10px;
        line-height: 14px;
        position: absolute;
        bottom: -18px;
      }

      & span.MuiFormControlLabel-label {
        color: $dark-gray-text-color;
      }

      & .MuiInputLabel-root {
        margin: 5px 0 0 0;
        color: $dark-gray-text-color;
      }

      & .MuiOutlinedInput-root {
        height: 50px;
        border-radius: 12px;
        color: $dark-gray-text-color;

        &.Mui-disabled {
          background-color: $gray;
        }

        &.filter-values-selected {
          background: $counter-lighter-red;
          color: $dark-red;
        }

        & .MuiSelect-select {
          padding-left: 8px;

          & strong {
            text-overflow: ellipsis;
            overflow: hidden;
            vertical-align: bottom;
          }
        }
      }

      .MuiTextField-root {
        & .MuiInputBase-input {
          padding-left: 8px;
        }
      }
    }

    &__form {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      width: 100%;

      &__error div {
        padding: 20px 0 0 0;
      }
    }
  }

  & .confirmation-modal-header {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;
    color: $mid-gray;

    & .confirmation-modal-title {
      padding: 20px 0 0 24px;
      font-weight: bold;
      font-size: 14px;
      line-height: 30px;
      color: $dark-gray-text-color;
    }

    & .confirmation-modal-message {
      align-items: center;
      justify-content: center;
    }
  }

  & .modal-buttons {
    display: flex;
    justify-content: center;
    padding: 32px 24px;
  }

  & .confirm-button,
  & .cancel-button {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 0 23px;
    height: 45px;
    width: 188px;
    border-radius: 12px;
    text-transform: none;

    &:hover {
      border: 1.5px solid $light-gray;
    }
  }

  & .confirm-button {
    color: $white;
    background-color: #8e0038;
    margin-right: 24px;

    &:disabled {
      background-color: $gray-mui-disabled-button-background;
    }

    &:hover {
      background-color: $mid-red-2;
    }
  }

  & .cancel-button {
    color: $mid-gray;
    margin-left: 24px;
  }
}
