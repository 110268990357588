@import 'src/assets/common.module';

div.documents-search {
  margin: 24px 0 0 0;
  border-radius: 12px;
  overflow: hidden;

  & .MuiGrid-container {
    height: 100%;
  }

  & &-main {
    padding: 24px 32px 0 25px;
    position: relative;
  }

  & &-search {
    display: flex;
    margin-bottom: 24px;
  }

  & &-search-fields {
    width: 100%;
  }

  .sequential-search-options {
    padding: 25px 0 0 33px;
    background: $lightest-gray;
  }

  & &-buttons {
    margin: 15px 0 24px -2px;

    & .MuiButton-root {
      text-transform: none;
      font-size: 14px;
      line-height: 2;
      margin-right: 4px;
      border-radius: 12px;
      box-shadow: none;
      height: 35px;
      width: 170px;

      & .documents-search-counter {
        background: $counter-lighter-red;
        margin-left: 5px;
        color: inherit;
      }

      &:disabled .documents-search-counter {
        background: $gray;
      }

      &.MuiButton-contained .documents-search-counter {
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }

  &-parent-filter {
    color: $dark-red;
    font-size: 0.75em;
    font-weight: bold;
    line-height: 2;
    margin-bottom: 20px;
  }
}

.documents-search-counter {
  color: $mid-gray;
  border-radius: 4px;
  height: 16px;
  min-width: 16px;
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  padding: 0 2px;
  background: $gray;
  display: inline-block;
  text-align: center;
}
