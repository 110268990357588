@import 'src/assets/common.module';

.bulk-metadata-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  min-width: 600px;
  max-width: 850px;
  height: 95%;
  max-height: 870px;
  background: $white;
  border-radius: 12px;
  outline: none;

  & .bulk-metadata-modal-header {
    padding: 20px 23px 0;
    height: 80px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;
    color: $mid-gray;

    & .bulk-metadata-modal-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 30px;
      color: $dark-gray-text-color;
    }
  }

  & .metadata-form {
    margin: 0 0 0 0;
    height: calc(100% - 80px);

    & .api-error {
      margin: 0 24px 0 24px;
      position: absolute;
      bottom: 70px;
    }

    & .metadata-form-fields {
      margin: 0 24px 0 24px;
      display: flex;
      column-gap: 24px;
      height: calc(100% - 90px);
      overflow: auto;
      overflow-x: hidden;

      & .metadata-form-block {
        width: 50%;
      }
    }

    & .metadata-form-submit {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 15px;
      width: 100%;

      & .MuiLoadingButton-root {
        width: 210px;
        flex: none;
      }
    }

    & .MuiFormControlLabel-root {
      padding-top: 0;
      padding-left: 6px;
    }
  }
}
