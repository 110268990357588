@import 'src/assets/common.module';

.warning-modal {
  & .modal-description {
    padding: 25px 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    & .warning {
      color: $orange;
      width: 44px;
      height: 44px;
    }
  }
}
