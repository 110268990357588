@import 'src/assets/common.module';

.uploading-modal-error {
  margin: 0 0 15px 0;

  & .description {
    margin: 14px 0 3px 0;
  }

  & .error-icon {
    color: $light-red;
    font-size: 1rem;
    vertical-align: middle;
  }

  & .list-item span {
    color: $dark-gray-text-color;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1.1;
  }

  & .MuiList-root {
    overflow-y: auto;
  }
}
