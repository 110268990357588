@import 'src/assets/common.module';

.saved-searches {
  .saved-searches-input {
    padding: 30px 30px 0 30px;
  }

  .search-input {
    width: 100%;
  }

  .search-name-column {
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;

    .search-name-column-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .MuiSvgIcon-root {
      font-size: 16px;
    }
  }
}
