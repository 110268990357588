@import 'src/assets/common.module';

.report-reason-modal.mars-modal {
  width: 520px;
  align-items: center;

  & .dismiss {
    width: 466px;
  }

  & div.label-status {
    display: none;
  }

  & .modal-description {
    width: 100%;
    color: $dark-gray-text-color;

    & .document-info-header {
      margin: 25px 0 0 0;
      height: 90px;
      width: 100%;
    }

    & .clause-item {
      margin-top: 7px;

      & .metadata {
        display: none;
      }
    }
  }

  & .success-message {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;

    & .clause-item {
      margin-top: 30px;
      width: 100%;
      & .content {
        margin-bottom: 10px;
      }
    }

    & .success-wording {
      font-weight: bold;
    }

    & svg.success-icon {
      width: 60px;
      height: 60px;
      color: $green;
      margin-bottom: 10px;
    }
  }

  & .comment-wording {
    --error-form-helper-height: 28px;
  }

  & .report-reason-form {
    border-radius: 12px;
    background: $white;
    margin-top: 20px;

    & .MuiFilledInput-root {
      font-size: 0.88rem;
      border: 1.5px solid $gray;
      border-radius: 12px;

      & .MuiInputBase-input {
        padding-top: 18px;
        padding-left: 16px;
      }

      & textarea.MuiInputBase-input {
        padding: 0 4px 0 0;
      }

      &:before,
      &:after {
        display: none;
      }

      &,
      &:hover,
      &.Mui-focused,
      & .MuiFilledInput-input:focus {
        background: none;
      }
    }

    & .reason-dropdown {
      min-height: 72px;
    }
  }

  .clause-item {
    .header {
      display: flex;
      flex-wrap: wrap;

      .title {
        max-width: 90%;
      }

      .MuiChip-root {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .clause-status-icon {
        margin-top: 0;
      }
    }
  }
}
