@import 'src/assets/common.module';

.export-confirmation-modal {
  font-size: 14px;
  line-height: 24px;
  width: 50%;
  margin: 0 auto;
  padding: 10px 0 16px;
  text-align: center;
  color: $dark-gray-text-color;

  .success-icon {
    color: $green;
  }
  .error-icon {
    color: $light-red;
  }
}
