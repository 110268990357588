@import 'src/assets/common.module';

div.save-search-select {
  & .MuiOutlinedInput-root {
    border-radius: 12px;
  }
  & .MuiOutlinedInput-input {
    padding: 10.5px;
  }
}

div.save-search-select-label {
  font-size: 0.75em;
  font-weight: bold;
  line-height: 2;
  display: flex;
  align-items: center;
}

span.save-search-select-icon {
  margin-right: 4px;
  margin-left: 3px;
  display: inherit;
  & .MuiSvgIcon-root {
    font-size: 15px;
  }
}
