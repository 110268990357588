@import 'src/assets/common.module';

button.document-bar-list__button-menu {
  color: $white;
  margin-left: -10px;
}

div.document-bar-list__menu {
  & .MuiPaper-root{
    border-radius: 12px;
  }
}
