@import "src/assets/common.module";


.automatic-upload-info-link{
  color: $white
}

.automatic-upload-info {
 font-size: 12px;
 line-height: 16px;
 color: $mid-gray;
 display: flex;
 align-items: center;
 cursor: pointer;

  & .automatic-upload-info-icon {
    font-size: 20px;
    margin-right: 8px;
  }
}
