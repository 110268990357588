@import 'src/assets/common.module';

#minimize-doc {
  &.modal-minimize {
    right: 68px;
    &--disable {
      border: 1px solid $lighter-gray;
      color: $lighter-gray;
      cursor: auto;
    }
  }
}
