@import 'src/assets/common.module';

.metadata-form {
  margin: 30px 32px 39px 32px;

  & .switch-form-control {
    margin-left: 4.5px;
  }

  & .MuiFormControl-root {
    min-height: 69px;
    width: 100%;

    & .MuiInputLabel-root {
      font-size: 0.88rem;
      padding-left: 4px;
    }

    & .MuiFilledInput-root {
      font-size: 0.88rem;
      border: 1.5px solid $gray;
      border-radius: 12px;

      & .MuiInputBase-input {
        padding-top: 18px;
        padding-left: 16px;
        small {
          display: none;
        }
      }

      & textarea.MuiInputBase-input {
        padding-top: 0;
        padding-left: 4px;
      }

      &:before,
      &:after {
        display: none;
      }

      &,
      &:hover,
      &.Mui-focused,
      & .MuiFilledInput-input:focus {
        background: none;
      }
    }

    & .MuiFormHelperText-root {
      margin: 1px 1px 0 7px;
      font-size: 0.63rem;
      color: $mid-gray;
      height: 17px;
      display: flex;
    }
  }

  & .MuiButton-root {
    line-height: 1.88;
    border-radius: 12px;
    text-transform: none;
    font-weight: bold;
    margin-top: 4px;
  }

  & .MuiTypography-root {
    font-size: 14px;
  }

  & .metadata-form-submit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    & .MuiButton-root {
      line-height: 44px;
      font-size: 15px;
      width: 100%;
      padding: 0;
    }
  }

  & .metadata-form-submit .single-button {
    flex: 1;
  }
}
.render-no-metadata {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px);
  color: $mid-gray-2;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}
