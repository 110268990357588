@import 'src/assets/common.module';

.minimize-box {
  width: 140px;
  height: 10px;
  position: absolute;
  bottom: 55px;
  top: auto;
}

@mixin ach-modal-file-viewer {
  .file-viewer {
    margin: 25px auto 0 auto;
  }
}

.metadata-edit-modal.document-view-modal {
  transition: 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
    transform 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1), width 0.5s 0.5s cubic-bezier(0.1, 1.2, 0.3, 1),
    opacity 0.3s;

  & .create-new-version-button {
    margin-top: 10px;
    margin-left: 30px;
    width: 160px;
  }

  & .MuiTabPanel-root {
    padding: 0;
    height: calc(100% - 71px);
    overflow: auto;
  }

  .document-view {
    @include ach-modal-file-viewer;
  }

  .document-view-modal__top-left-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    margin-top: 18px;
    background-color: $lightest-gray;
    padding: 0 0 0 32px;
  }

  .document-view-modal__top-right-bar {
    display: flex;
    align-items: center;
    height: 56px;
    margin-bottom: 15px;
  }

  .document-view-header {
    margin-top: 8px;

    & .document-view-tabs {
      margin-left: 32px;
    }
  }

  .MuiButtonBase-root {
    text-transform: none;
  }

  .MuiTab-root {
    padding: 0;
    min-width: 0;
    margin-right: 20px;
  }

  .Mui-selected {
    text-shadow: 0 0 1px black;
  }

  .document-view-menu {
    height: 24px;
    padding: 5px 5px 2px 3px;
    display: flex;
    align-items: center;

    & .MuiButtonBase-root {
      color: inherit;
      line-height: inherit;
    }

    & button {
      font-size: 12px;
      margin-right: 8px;

      &.Mui-disabled {
        color: $mid-gray-2;
      }

      & .document-view-icons {
        height: 14.4px;
        width: 14.4px;
      }
    }

    & button.document-view__compare--remove {
      color: $light-red;
    }
  }

  .ai-chat-button.MuiButton-root {
    background: $eds-primary-200;
    color: $eds-primary-800;
    font-weight: bold;
    border-radius: 12px;
    padding: 6px 15px;
  }
}
