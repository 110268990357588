@import "src/assets/common.module";

.mars-snackbar {
  & .MuiSnackbarContent-root {
    border-radius: 13px;
    height: 44px;
  }

  & .MuiSnackbarContent-message {
    padding: 6px 0;
    display: flex;
    align-items: center;

    & small {
      margin: 0 0.73em;
      color: $mid-gray-2;
    }

    & .MuiCircularProgress-root {
      margin-right: 6px;
    }

    & .done-icon {
      color: $green;
      width: 0.5835em;
      height: 0.5835em;
      margin-right: 8px;
    }
  }

  & .MuiButton-root {
    border-radius: 12px;
    text-transform: none;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1.85;
    height: 32px;

    &.Mui-disabled {
      color: $white;
      background: $mid-gray;
    }

    &.MuiLoadingButton-loading {
      color: transparent;
    }
  }
}
