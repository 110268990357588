@import "src/assets/common.module";

.modal-close {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border: 1px solid $light-gray;
  border-radius: 4px;
  color: $mid-gray;
  padding: 3px;
}
