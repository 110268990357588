@import 'src/assets/common.module';

div.tab-items-list-grid {
  color: $mid-gray;
  padding-top: 20px;
  border: none;

  .row-minimized {
    background-color: rgba(0, 0, 0, 0.04);

    &.MuiDataGrid-row:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .row-compared {
    &,
    &.MuiDataGrid-row:hover {
      background-color: $top-light-red;
    }
  }

  .MuiDataGrid-row:hover {
    cursor: pointer;
    background-color: transparent;
  }

  .row-minimized-disabled {
    &.MuiDataGrid-row:hover {
      cursor: default;
    }
  }

  .MuiDataGrid-cell:focus,
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-columnHeader:focus,
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  .tab-items-details-column {
    display: flex;
    height: 100%;
    align-items: center;
    overflow: hidden;

    .tab-items-details-column__header {
      display: flex;
      gap: 10px;
    }

    .tab-items-details-column__text {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      margin-left: 15px;

      div {
        color: $dark-gray-text-color;
      }
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      > :nth-child(n + 2) {
        color: inherit;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .tab-items-check-column {
    padding: 4px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span:last-of-type {
      color: $green;
      font-weight: bold;
    }
    &-compare {
      display: flex;
      align-items: center;
      button {
        font-size: 10px;
        padding: 0;
        & .MuiButton-startIcon {
          margin-right: 4px;
          margin-left: 0;
        }
      }
      svg {
        font-size: 16px;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      svg {
        margin-right: 4px;
        font-size: 18px;
      }
    }
  }
}
