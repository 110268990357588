.edit-tag-form {
  &__tags {
    grid-column: span 2;
  }

  &__error div.api-error {
    padding-top: 40px;
    margin-bottom: -20px;
  }
}
