@import 'src/assets/common.module';

.modal-close-button.MuiIconButton-root {
  position: absolute;
  right: 12px;
  top: 12px;
  height: 24px;
  width: 24px;
  border: 1px solid $light-gray;
  border-radius: 4px;
  color: $mid-gray;

  svg {
    width: 0.667em;
    height: 0.667em;
  }
}
