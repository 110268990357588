@import 'src/assets/common.module';

.settings {
  & .settings-container {
    display: grid;
    grid-template-columns: 245px auto;
    column-gap: 16px;
    min-height: 85vh;
  }
  & .settings-container-menu {
    display: flex;
    flex-direction: column;
  }
  & .settings-container-menu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 30px 0 0 30px;
    text-decoration: none;
    color: inherit;

    svg {
      opacity: 0.6;
      margin-right: 16px;
    }

    &:hover,
    &.active {
      color: $dark-red;
      text-shadow: 0 0 1px black;
    }
    &.disabled-link {
      color: $mid-gray;
      pointer-events: none;
    }
  }
}

div.settings-header {
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 0 16px 0;
  background-color: $gray;

  & .settings-title {
    height: 24px;
    width: 198px;
    color: $dark-gray-text-color;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0 0 0 19px;
  }
}
