@import 'src/assets/common.module';

.upload-clause {
  background-color: $gray !important;

  &___header {
    padding: 22px 0 16px 19px;
    background-color: $gray;
    color: $dark-gray-text-color;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    height: 72px;
  }
  &___title {
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    color: $dark-gray-text-color;
  }
  &___description {
    font-size: 12px;
    line-height: 16px;
    color: $mid-gray;
    margin-bottom: 24px;
  }
  &___wording {
    width: 638px;
    margin-right: 140px;
    & textarea {
      height: 100% !important;
    }
    & .MuiFilledInput-root {
      height: 740px;
    }
  }

  &___info {
    width: 406px;

    .clause-status-icon {
      float: right;
      margin-top: 12px;
    }
  }

  &___form-control .MuiTypography-root {
    font-size: 14px;
  }

  &___buttons {
    display: flex;
    align-content: space-around;
    justify-content: space-between;
    padding-top: 10px;
  }

  &___update_clause_warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    margin-top: 15px;
    margin-left: 8px;
    @at-root svg#{&}-icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  & button {
    text-transform: none;
    width: 150px;
  }

  & button.cancel-button {
    color: $mid-gray;
    border: 1px solid $light-gray;
    &:hover {
      border: 1.5px solid $light-gray;
    }
  }

  & form {
    display: flex;
    padding-bottom: 50px;
  }

  .metadata-form {
    margin: 0;
    padding: 30px 112px 39px 32px;
    border-radius: 12px;
    background: $white;
  }

  & .MuiFormHelperText-root {
    margin-right: 0;
  }

  & .adjust-clause-warning {
    margin-top: 30px;
    color: $orange;
  }
}
