@import 'src/assets/common.module';

.approval-tag {
  height: 20px;
  &.approved {
    background-color: $lighter-green;
    color: $light-green;
  }
  &.rejected {
    background-color: $top-light-red;
    color: $light-red;
  }
}
