@import 'src/assets/common.module';

.bookmark-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 0 16px 0;
  background-color: $gray;
  height: 72px;

  &__search {
    margin: 0 0 28px 0;
  }

  &__link {
    display: inline-flex;
    border: 1px solid $light-gray;
    border-radius: 4px;
    margin-right: 15px;
  }

  &__left {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__title {
    height: 24px;
    color: $dark-gray-text-color;
    font-family: $font-family;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 4px;
    margin-right: 15px;
  }

  &__folder {
    color: $mid-gray;
    margin-right: 10px;
  }

  &__more {
    color: $mid-gray;
  }

  & .MuiButtonBase-root {
    margin-left: -10px;
  }
}
