@import 'src/assets/common.module';

.report-status {
  display: flex;
  align-items: center;

  & svg {
    width: 19px;
    height: 19px;
  }

  &__label {
    margin: 0 0 0 6px;
    line-height: 19px;
  }

  &.done svg {
    color: $green;
  }
  &.pending svg {
    color: $light-orange;
  }

  &.failed svg {
    color: $light-red;
  }
}
