@import 'src/assets/common.module';

.comments-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 32px;
  margin-right: 15px;

  .comments-list {
    &--container__empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: $mid-gray;
    }
  }

  .comment-form-container {
    margin-right: 17px;
    margin-bottom: 15px;

    &__form {
      position: relative;
      border-radius: 12px;
      background: $white;
      margin-top: 20px;
      height: 136px;

      & .custom-text-field-counter {
        margin-bottom: 0;
      }

      & .MuiFilledInput-root {
        font-size: 0.88rem;
        border: 1.5px solid $light-gray;
        border-radius: 12px;
        padding: 7px 7px 30px;
        height: 120px;

        & .MuiInputBase-input {
          padding-top: 18px;
          padding-left: 16px;
        }

        & textarea.MuiInputBase-input {
          padding: 0 4px 0 0;
        }

        &:before,
        &:after {
          display: none;
        }

        &,
        &:hover,
        &.Mui-focused,
        & .MuiFilledInput-input:focus {
          background: none;
        }
      }

      button {
        position: absolute;
        right: 5px;
        bottom: 15px;
        font-weight: bold;
        background: none;

        svg {
          color: $dark-red;
        }

        &:hover {
          background: none;
        }
        &.MuiLoadingButton-root {
          & .MuiTouchRipple-root {
            display: none;
          }
        }
      }

      & .MuiTextField-root {
        flex-grow: 1;

        & textarea {
          height: 100% !important;
          overflow-y: auto !important;
        }

        p.MuiFormHelperText-root {
          position: absolute;
          bottom: -20px;
        }
      }
    }
  }

  .comments-list {
    overflow-y: scroll;
    padding-right: 17px;
    margin-top: 10px;
  }
}
