@import 'src/assets/common.module';

button.upload-files {
  border-radius: 12px;
  background-color: $dark-red;
  height: 45px;
  width: 159px;
  color: $white;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.35px;
  line-height: 24px;
  text-align: center;
  text-transform: none;

  &:hover {
    background-color: $dark-red;
  }

  &.small {
    height: 32px;
    width: 123px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }

  &.draft-mode {
    margin-right: 0;
    padding: 6px 5px;
    width: 144px;
  }

  &.draft-mode-selected {
    background-color: $mid-gray;
  }
}
