@import 'src/assets/common.module';

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 40px;
  width: 512px;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  background: $white;
  border-radius: 12px;
  text-align: center;
  color: $dark-gray-text-color;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-title {
    font-family: $font-family-fedra;
    font-size: 24px;
    line-height: 30px;
  }

  &-instruction {
    line-height: 19px;
  }

  & button.MuiButton-root {
    box-shadow: none;
    color: $white;
    text-transform: none;
    font-size: 16px;
    padding: 20px;
    width: 340px;
    height: 56px;
    background-color: $dark-red;
    border: 1px solid $mid-gray-2;
    border-radius: 12px;
    &.Mui-disabled {
      color: $gray-mui-disabled-button-label;
      border: 1px solid $gray-mui-disabled-button-border;
      background-color: $body-background-color;
    }
  }

  & .ergo-logo {
    height: 32px;
    align-items: center;
  }

  & &-options {
    & .MuiLink-root {
      font-weight: 600;
      pointer-events: auto;
    }

    & .MuiSvgIcon-root {
      font-size: 16px;
    }

    & .MuiFormControlLabel-root {
      margin-right: 0;
      pointer-events: none;
    }

    & .MuiCheckbox-root {
      pointer-events: auto;
    }

    & .MuiTypography-root.MuiTypography-body1 {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
