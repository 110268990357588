@import 'src/assets/common.module';

.draft-label {
  border-radius: 8px;

  &.MuiChip-root {
    background-color: $ergo-ice-blue-dark-3;
    font-size: 12px;
    height: 20px;

    .MuiChip-label {
      color: $white;
    }
  }
}
