@use './FileViewer.module' as FileViewer;
@import 'src/assets/common.module';

$toolbar-height: 40px;

.file-viewer {
  &--pdf-centered {
    .react-pdf__Page {
      width: min-content;
      margin: 0 auto;
    }
  }

  &--html {
    iframe {
      display: block;
      border: none;
      width: 100%;
      height: calc(100% - $toolbar-height);
      background: #ffffff;
    }
  }

  &--txt {
    &__content {
      overflow: auto;
      width: 100%;
      height: calc(100% - $toolbar-height);
      background: #ffffff;
      padding: 8px;

      div {
        transform-origin: 0 0;
        white-space: pre-line;
        height: 100%;
      }

      &__text--added,
      ins {
        background-color: #ceede7 !important;
      }

      &__text--removed,
      del {
        background-color: #ffc9cb !important;
      }
    }
  }

  &--document {
    overflow: scroll;
    height: calc(100% - $toolbar-height);
    width: 100%;
    position: relative;
  }

  &--navigation {
    display: flex;
    justify-content: center;
    height: $toolbar-height;
    & div.search-input {
      &-doc-view {
        display: flex;
        align-items: center;
        width: calc(100% - 80px);
      }
      &.MuiTextField-root {
        width: 100%;
        margin-right: 8px;
        & .MuiInputBase-input {
          background-color: $white;
          height: 1em;
        }
      }
    }
  }

  &--highlight-info {
    flex-shrink: 0;
    padding: 8px;
  }

  mark,
  markjs {
    background: FileViewer.$mark-background;
    color: #000000;
    scroll-margin: FileViewer.$scroll-offset * 1px;

    &.current {
      background: FileViewer.$mark-current-background;
      color: FileViewer.$mark-current-color;
    }
  }

  clause {
    background: FileViewer.$clause-background-opacity;

    clause {
      background: none;
    }
  }
}
