@import 'src/assets/common.module';

.report-section {
  & .MuiFormControl-root {
    width: 170px;

    & .MuiOutlinedInput-root {
      border-radius: 12px;
    }
  }

  & .splitter {
    padding: 0 15px;
  }

  & .title {
    font-weight: 700;
    margin-bottom: 10px;
  }

  & .description {
    font-size: 14px;
    color: $dark-gray-text-color;
  }

  & .actions {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    & .button-with-icon {
      margin-left: 0;
    }
  }
}
