@import 'src/assets/common.module';

div.label-status {
  border-radius: 8px;
  padding: 4px 6px;
  line-height: 16px;

  & span {
    height: 16px;
    width: 41px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }

  &.label-active,
  &.label-ready {
    background-color: $lighter-green;

    & span {
      color: $light-green;
    }
  }

  &.label-not-active,
  &.label-deleting,
  &.label-failed {
    background-color: $top-light-red;

    & span {
      color: $light-red;
    }
  }

  &.label-processing {
    background-color: $lighter-orange;

    & span {
      color: $light-orange;
    }
  }
}
