@import 'src/assets/common.module';

.clause-item-preview {
  .MuiChip-root {
    font-size: 12px;
    height: 20px;
    margin: 2px;
    background-color: $gray;
  }
  .MuiChip-label {
    color: $mid-gray;
  }
}
.pair {
  .MuiChip-root {
    background-color: $gray-2;
  }
}
