@import 'src/assets/common.module';

.single-input-modal {
  .modal-title {
    margin-bottom: 2px;

    .rectangle {
      display: none;
    }
  }

  .single-input-modal-description {
    font-size: 12px;
    margin-bottom: 16px;
  }

  .single-input-modal-name {
    margin-bottom: 13px;

    .MuiOutlinedInput-root {
      font-size: 14px;
      height: 43px;
    }
  }

  .single-input-modal-helper-button {
    display: none;
  }
}
.single-input-modal-helper-text {
  margin: 0 0 0 -14px;
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 12px;
  color: $orange;

  svg {
    font-size: 14px;
    margin-right: 7px;
  }
}
