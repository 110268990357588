div.clauses-list-grid {
  padding: 16px 40px;
  & .MuiDataGrid-cell {
    white-space: normal;
  }
  & .clause-item-cell {
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: normal;
  }
  & .MuiDataGrid-cell--withRenderer:last-child {
    justify-content: space-around;
  }
}
