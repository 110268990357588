@import 'src/assets/common.module';

%chat-message-bubble {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12);
  font-size: 14px;
  line-height: 22px;
  color: $dark-gray-text-color;
  white-space: pre-line;
  word-wrap: break-word;
  max-width: 60%;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $white;

  .chat-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 62px;
    background-color: $lightest-gray;

    .chat-logo {
      height: 20px;
      margin-right: 8px;
    }

    span {
      height: 20px;
      line-height: 23px;
      font-size: 20px;
    }
  }

  .chat-error {
    color: $orange;
    font-size: 0.7em;
    padding: 10px 24px 0;
    display: flex;
    align-items: center;
    line-height: 1em;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .chat-message-stream {
    flex-grow: 1;
    overflow-y: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    .chat-message-user {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 12px;

      .chat-message-user-message {
        @extend %chat-message-bubble;
        background: $lightest-gray;
      }
    }

    .chat-message-llm {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 12px;

      .chat-message-llm-message {
        @extend %chat-message-bubble;
        background: $top-light-red;

        .chat-message-quote-button {
          height: 20px;
          font-size: 0.7rem;
          margin-right: 1px;

          &--current {
            background-color: $clause-highlight-opacity;
          }

          span {
            padding-left: 6px;
            padding-right: 6px;
          }
        }
      }
    }
  }

  .llm-question-input-container {
    padding: 15px;
    position: relative;

    .llm-clear-button {
      color: $dark-red;
      position: absolute;
      bottom: 22.5px;
      z-index: 1;

      &:disabled {
        color: rgba(0, 0, 0, 0.26);
      }
    }

    .llm-question-input-form {
      display: flex;
      flex-direction: row;
      align-items: center;

      .llm-model-select {
        position: absolute;
        top: -17px;
        right: 0;
        padding: 0 25px 0 10px;
        background: $white;

        .MuiOutlinedInput-root {
          height: 24px;
          font-size: 0.8em;
          border-radius: 8px;
        }
      }

      .llm-message-input {
        padding-left: 40px;
        padding-right: 10px;
        .MuiInputBase-root {
          border: 1.5px solid $light-gray;
          border-radius: 12px;
          font-size: 0.85rem;
          margin-right: 12px;
        }

        .MuiOutlinedInput-notchedOutline {
          border: 0;
        }

        .MuiInputBase-input {
          margin-right: 30px;
          max-height: 100px;
          overflow: auto !important;
        }
      }

      .llm-submit-button {
        right: 28px;
        bottom: 22.5px;
        position: absolute;

        button.MuiIconButton-root {
          color: $dark-red;

          &:disabled {
            color: $light-gray;
          }
        }
      }
    }
  }
}

.chat-message-quote-tooltip {
  max-width: 300px;
}
