@import 'src/assets/common.module';

.link-notice {
  margin-right: 10px;
  float: right;
  color: $mid-gray;
  text-decoration: underline;
  cursor: pointer;
}

.link-notice:hover {
  color: $dark-gray-text-color;
  text-decoration: none;
}

.popoverBox {
  padding: 16px 24px;
  width: 610px;

  & .heading {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: bold;
  }

  & .list {
    padding-inline-start: 20px;
  }

  & .listItem {
    color: $dark-gray-text-color;
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: bold;
  }

  & .example {
    font-size: 12px;
    color: $mid-gray;
    margin-bottom: 16px;
  }

  & .tableCell {
    font-size: 12px;
    padding: 10px;
    &.italic {
      font-style: italic;
    }
  }

  & .divider {
    margin-bottom: 16px;
  }

  & div.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 94px;
  }
}
