@import 'src/assets/common.module';

div.search-input {
  &.MuiTextField-root {
    & .MuiOutlinedInput-root {
      border-radius: 12px;
    }

    & .MuiSvgIcon-root {
      color: $mid-gray;
      margin: 0 5px 0 3px;
    }

    & .MuiInputBase-input {
      color: $dark-gray-text-color;
      font-size: 14px;
      height: 2em;
    }
  }
}
