@import 'src/assets/common.module';

.metadata-view {
  padding-bottom: 40px;

  .metadata-view-block {
    font-size: 14px;
    margin: 40px 10px 0 32px;

    .document-parents {
      color: $dark-red;
      margin-bottom: 7px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      &__blocked {
        &:hover {
          cursor: default;
          text-decoration: none;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-icon-container {
      display: flex;
    }

    .bold {
      font-weight: bold;
    }
    .space-right {
      margin-right: 20px;
    }
    .space-up {
      margin-top: 16px;
    }
    .space-down {
      margin-bottom: 8px;
    }
    .chip {
      border-radius: 10px;
      height: 24px;
    }
  }
}

.icon-checked {
  &.MuiSvgIcon-root {
    width: 1rem;
    height: 1rem;
  }
}
