@import 'src/assets/common.module';

.document-bar-list {
  border-radius: 12px;
  display: flex;
  vertical-align: middle;
  gap: 3px;
  width: 85%;

  &__backdrop {
    color: $white;
  }
}

.document-bar-list--compare-disabled {
  width: 100%;
}
