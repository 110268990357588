@import 'src/assets/common.module';

div.uploaded-file-list {
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 0 12px 0 0;
  align-items: stretch;

  &.uploaded-file-list-no-docs {
    align-items: center;
  }
}
