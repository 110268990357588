div.reports {
  .reports-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    margin-left: 19px;
    padding: 22px 0 16px 0;
    height: 72px;
    line-height: 24px;
  }
  & .tab-panel {
    margin-bottom: 20px;
    background: none;
    & div.documents-list {
      border-radius: 0 12px 12px 12px;
    }
  }
}
