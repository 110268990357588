@import 'src/assets/common.module';

html {
  background-color: $gray;
}

#root {
  background-color: $gray;
}

div.SnackbarContainer-root.app-snackbar-provider-container-root {
  z-index: $z-index-mui-notification;
}
