@import 'src/assets/common.module';

.sequential-search-fields-arrow-line {
  position: relative;

  &:after {
    content: '';
    width: 1px;
    background: $light-gray;
    display: block;
    position: absolute;
    right: -1px;
    top: 30px;
    bottom: 30px;
  }
}

.sequential-search-fields {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__add.MuiButton-root {
    width: 170px;
    color: $mid-gray;
    margin-left: -3px;
    text-transform: none;
    border-radius: 12px;
    font-size: 14px;
    font-weight: normal;
    height: 44px;

    svg {
      width: 22px;
      margin: 0 7px;
    }
  }

  &__collapse.MuiButton-root {
    font-size: 14px;
    margin: 1px 0 2px 9px;
    cursor: pointer;
    color: $dark-gray-text-color;
    text-align: left;
    display: block;
    text-transform: none;
    border-radius: 12px;
    height: 37px;

    svg {
      display: inline-block;
      margin: -2px 0px -7px 1px;
    }
  }
}
