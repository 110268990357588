@import 'src/assets/common.module';

.item-name {
  margin-left: 16px;
  font-weight: bold;
  color: $dark-gray-text-color;
  max-width: 94%;

  &--clickable {
    cursor: pointer;
  }
}
