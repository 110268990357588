@import 'src/assets/common.module';

.edit-clause-text-field {
  margin: 25px auto 0 auto;
  width: 93%;
  height: calc(100% - 107px);
  & .MuiFormControl-root {
    height: 100%;

    & .MuiFilledInput-root {
      font-size: 0.88rem;
      border: 1.5px solid $gray;
      border-radius: 12px;
      height: 100%;
      background: $white;

      & textarea.MuiInputBase-input {
        padding-top: 0;
        padding-left: 4px;
        height: 100% !important;
      }

      &:before,
      &:after {
        display: none;
      }
    }
  }
}
